import { IPunterResults } from "../resultsSlice";

export const ERROR_GENERIC_MESSAGE: string =
  "There was an error in retrieving data, please try again after some time.";

export enum UPDATE_LEAGUE_ACTION_CODE_MAP {
  DISABLE = 0,
  APPROVED = 1,
  REJECTED = 2,
  REMOVED = 3,
  EXITED = 4
}

export interface ILeagueNotificationItem {
  leagueNotificationId: number;
  notificationType: number;
  punterId: number;
  message: string;
  createdDate: string;
}

export interface ILeagueMemberItem {
  punterId: number;
  punterAlias: string;
}

export interface IMyLeagueItem {
  leagueName: string;
  rank: number;
  leagueCode: string;
  leagueStatus: boolean;
  punterLeagueStatus: number;
  punterOwnerId: number;
  totalPlayers: number;
  unreadNotifications: number;
}

export interface IJoinedLeagueItem extends IMyLeagueItem {}

export interface IFriendsLeagueCreateQueryData {
  punterId: number;
  leagueName: string;
}

export interface IFriendsLeagueJoinALeagueQueryData {
  punterId: number;
  leagueCode: string;
}

export interface IFriendsLeagueUpdateALeagueQueryData {
  punterId: number;
  leagueCode: string;
  action: number;
}

export interface IGetLeagueNotificationsQueryData
  extends IFriendsLeagueJoinALeagueQueryData {}

export interface IGetLeaguesLederBoardQueryData {
  leagueCode: string;
  meetingId: number;
  pageNumber: number;
  searchString: string;
}

export interface IGetLeagueLeaderboardDetailsQueryData {
  punterId: number;
}

export interface IGetPunterLeaguesQueryData
  extends IGetLeagueLeaderboardDetailsQueryData {}

export interface ICurrentMeetingForLeaderboard {
  id: number;
  name: string;
}

export interface IGetLeagueMembersQueryData {
  leagueCode: string;
}

export interface ILeagueStatsDataQueryData {
  punterId: number;
  raceCourseId: number;
  date: string;
}

export interface IDeleteALeagueQueryData {
  punterId: number;
  leagueCode: string;
  newStatus: number;
}

export interface INewleagueCreationData {
  requestedLeagueToCreate: string | null;
  leagueCreatedJustNow: string | null;
  createdLeagueCode: string | null;
  errorInLeagueCreation: string | null;
}

export interface IShareLeagueData {
  leagueToShare: string;
}

export interface IJoinALeagueData {
  leagueCode: string;
  leagueName: string;
  joinRequestSuccessful: boolean;
  errorInJoinLeague: string | null;
}

export interface ILeagueLeaderboardItem {
  punterId: number;
  leagueRank: number;
  punterAlias: string;
  meetingPoints: number;
  winners: number;
  secondPlaces: number;
  thirdPlaces: number;
}

export interface ILeagueStatsMeeting {
  raceCourseId: number;
  raceCourseName: string;
}

export interface IStandardLeaderboardData {
  meetingId: string;
  hasResulted: boolean;
}

export interface ILeagueStatsData {
  punterID: number;
  totalSelections: number;
  totalWins: number;
  winPercentage: number;
  winners: number;
  selections: number;
  top10: number;
  top50: number;
  top500: number;
  top1000: number;
  maxWinPercentage: number;
  overallAverageWinPercentageAllPunters: number;
  overallWinners: number;
  overallSelections: number;
  bestWinsByPunterWinners: number;
  bestWinsByPunterSelections: number;
  bestPosition: number;
  bestPositionSelections: number;
  puntersOutPerformed: number;
  highScore: number;
  highestScoreByAnyPunter: number;
  raceCourseName: string;
  raceCourseNameBestWinPercentage: string;
  bestWinsByPunterRaceCourseName: string;
  bestWinsByPunterDate: string;
  bestPositionRaceCourseName: string;
  bestPositionDate: string;
  highestScoreByAnyPunterRaceCourseName: string;
  highestScoreByAnyPunterDate: string;
}

export interface ILeagueLeaderboardPuntersDataItem {
  raceNumber: number;
  saddle: number;
  horseName: string;
  points: number;
  winners: number;
  racePosition: number;
}

export interface IPunterSearchRequest {
  meetingId: string;
  pageId: string;
  numberPerPage: string;
  searchFor: string;
}

export interface IFriendsLeague {
  myLeagues: IMyLeagueItem[] | null;
  joinedLeagues: IJoinedLeagueItem[] | null;
  newLeagueCreationData: INewleagueCreationData | null;
  shareALeagueData: IShareLeagueData | null;
  joinALeagueData: IJoinALeagueData | null;
  currentLeagueToManage: IMyLeagueItem | null;
  toDisplayManageForLeague: string | null;
  currentNotifications: ILeagueNotificationItem[] | null;
  currentLeagueMembers: ILeagueMemberItem[] | null;
  toForceUpdateNotifications: boolean;
  errorMessageOnRejection: string | null;
  currentMeetingForLeaderboard: ICurrentMeetingForLeaderboard | null;
  currentLeagueForLeaderboard: IMyLeagueItem | null;
  currentLeaderboardPageData: ILeagueLeaderboardItem[] | null;
  currentLeaderboardPunterDetail: ILeagueLeaderboardPuntersDataItem[] | null;
  currentLeaderboardTotalPages: number;
  currentLeaderboardLastRank: number;
  currentPageNumber: number;
  isFetchingLeaderboard: boolean;
  statsMeetings: ILeagueStatsMeeting[] | null;
  leagueStatsData: ILeagueStatsData | null;
  isQueriedLeagueToJoinChecked: boolean;
  leaderboardMaxPages: number;
  mainLeaderboardStatusData: {
    [meetingid: string]: IStandardLeaderboardData;
  } | null;
}

export type TPendingAction<T> = {
  meta: {
    arg: T;
    requestId: string;
    requestStatus: string;
  };
};

export type TRejectedAction<T> = {
  meta: {
    aborted: boolean;
    arg: T;
    requestId: string;
    requestStatus: string;
    rejectedWithValue: boolean;
    condition: boolean;
  };
  error: {
    code: string;
    message: string;
    name: string;
    stack: string;
  };
};

export interface IMessages {
  responseMessage: string;
  responseMessageDetail: string;
  responseStatus: number;
}

export interface IPunterLeague {
  leagueName: string;
  rank: number;
  leagueCode: string;
  leagueStatus: boolean;
  punterLeagueStatus: number;
  punterOwnerId: number;
  totalPlayers: number;
  unreadNotifications: number;
}
export interface IPunterLeagueResponse {
  ownedLeagues: IPunterLeague[];
  joinedLeagues: IPunterLeague[];
}

export interface ILeagueCreation {
  punterId: number;
  leagueName: string;
  leagueCode: string;
}

export interface ILeagueCreationResponse extends IMessages {
  createdLeague: ILeagueCreation;
  errorCode: number;
}

export enum PuntersLeagueStatus {
  Pending,
  Approved,
  Rejected,
  Removed,
  Exit
}

export interface IUpdatePunterLeagueStatusResponse {
  leagueName: string;
  errorCode: number;
  updatePunterLeagueStatus: {
    punterId: number;
    leagueCode: string;
    punterLeagueStatus: PuntersLeagueStatus;
  };
  responseMessage: string;
  responseMessageDetail: string;
  responseStatus: number;
}

export interface ILeagueNotificationsResponse {
  leagueNotifications: ILeagueNotificationItem[];
}

export interface ILeagueMembersResponse {
  punterOwnerId: number;
  punterOwnerAlias: string;
  leagueMembers: ILeagueMemberItem[];
  errorCode: number;
}

export interface IRemoveALeagueMember {
  removed: any;
  updatedList: ILeagueMemberItem[];
}

export interface IPunterLeaderboardDetails {
  raceNumber: number;
  saddle: number;
  horseName: string;
  points: number;
  racePosition: number;
}

export interface IPunterLeaderboardDetailResponse extends IMessages {
  punterLeaderboardDetails: ILeagueLeaderboardPuntersDataItem[];
}

export interface ILeagueLeaderboardMeetings {
  meetingId: number;
  raceCourseName: string;
  meetingDate: string;
}

export interface ILeagueLeaderboardMeetingsResponse {
  leagueLeaderboardMeetings: ILeagueLeaderboardMeetings[];
}

export interface IGetLeagueLeaderboard
  extends ILeagueLeaderboardMeetingsResponse,
    IMessages {
  pageNumber: number;
  /**
   * Doesn't seem to match the return type defined on the API but included because the slice seems to require it in the reducer.
   */
  currentLeaderboard: any;
  /**
   * Doesn't seem to match the return type defined on the API but included because the slice seems to require it in the reducer.
   */
  maxPages: any;
}

export interface ILeagueStatsMeetings {
  raceCourseId: number;
  raceCourseName: string;
}

export interface ILeagueStatsMeetingsResponse extends IMessages {
  leagueStatsMeetings: ILeagueStatsMeetings[];
}

interface ISelectedHorseDTO {
  userId: string;
  raceNumber: number;
  saddle: number;
  horseName: string;
}

export interface IPunterInfoFromSearch {
  punters: IPunterResults[];
}

export interface IPunterLeagueStats {
  punterID: number;
  totalSelections: number;
  totalWins: number;
  winPercentage: number;
  winners: number;
  selections: number;
  raceCourseName: string;
  top10: number;
  top50: number;
  top500: number;
  top1000: number;
  raceCourseNameBestWinPercentage: string;
  maxWinPercentage: number;
  overallAverageWinPercentageAllPunters: number;
  overallWinners: number;
  overallSelections: number;
  bestWinsByPunterWinners: number;
  bestWinsByPunterSelections: number;
  bestWinsByPunterRaceCourseName: string;
  bestWinsByPunterDate: string;
  bestPosition: number;
  bestPositionSelections: number;
  bestPositionRaceCourseName: string;
  bestPositionDate: string;
  puntersOutPerformed: number;
  highScore: number;
  highestScoreByAnyPunter: number;
  highestScoreByAnyPunterRaceCourseName: string;
  highestScoreByAnyPunterDate: string;
}

export interface IPunterLeagueStatsResponse extends IMessages {
  currentPunterLeagueStats: IPunterLeagueStats;
}

enum LeagueStatus {
  Inactive,
  Active
}

export interface IUpdateLeague {
  punterOwnerId: number;
  leagueCode: string;
  leagueStatus: LeagueStatus;
}

export interface IUpdateLeagueResponse {
  errorCode: number;
  updatedLeague: IUpdateLeague;
}

export interface IBasePunterDTO {
  userId: string;
  rank: number;
  total: number;
  bonus: number;
  racePoints: number;
  winners: number;
  places: number;
  selections: number;
  totalPunters: number;
  selectedHorses: ISelectedHorseDTO[];
  date: string;
  totalSelections: number;
  secondPlace: number;
  thirdPlace: number;
}

export interface IDailyPunterDTO {
  punterId: number;
  /**
   * Added in the 'punters' key even to make the code work in the
   * **checkHasLeaderboardDataForMeeting** meeting in the friendsLeague slice.
   * THe key however is not present in the return type specified in the API.
   */
  punters: any;
}

export interface IGetDailyLeaderboardResponse {
  dailyLeaderboard: IDailyPunterDTO;
}
